<template>
  <div
    class="-mx-4 sm:mx-0"
    :class="[isAvailableInvoicesList ? 'lg:w-9/12' : 'lg:w-full']"
  >
    <template v-if="isAvailableInvoicesList">
      <div class="bg-white pb-8 sm:p-8">
        <div class="hidden mb-4 sm:flex">
          <div class="w-3/12 pr-2">
            <h3 class="text-xl">{{ $t('number') }}</h3>
          </div>
          <div class="w-4/12 px-2">
            <h3 class="text-xl">{{ $t('date_range') }}</h3>
          </div>
          <div class="w-3/12 pl-2">
            <h3 class="text-xl">{{ $t('net_amount') }}</h3>
          </div>
        </div>
        <invoices-item
          v-for="invoice in invoicesMock"
          :key="invoice.id"
          :invoice="invoice"
        />
        <hr class="hidden mt-8 border-grey-30 sm:block" />
        <pc-pagination
          class="mt-4"
          :total="32"
          :of="$t('of')"
          :items="$t('items')"
          @change="$emit('change', $event)"
        />
      </div>
    </template>
    <no-invoices v-else></no-invoices>
  </div>
</template>

<script>
import { PcPagination } from '@je-pc/ui-components'
import InvoicesItem from '../components/InvoiceItem'
import NoInvoices from '../components/NoInvoices'

export default {
  components: {
    PcPagination,
    InvoicesItem,
    NoInvoices
  },
  data: () => ({
    invoicesMock: [
      {
        id: '678292',
        dateRange: '11/10/2020 — 20/10/2020',
        netAmount: '£6790'
      },
      {
        id: '67829',
        dateRange: '15/10/2020 — 15/10/2020',
        netAmount: '£679'
      },
      {
        id: '6789290',
        dateRange: '15/10/2020 — 15/10/2020',
        netAmount: '£11942'
      }
    ]
  }),
  computed: {
    isAvailableInvoicesList() {
      return this.invoicesMock.length !== 0
    }
  }
}
</script>
