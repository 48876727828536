<template>
  <div class="container">
    <h1 class="leading-none mb-6 font-bold text-3xl">
      {{ pageTitle }}
    </h1>
    <invoices-list />
  </div>
</template>

<script>
import InvoicesList from '../components/InvoicesList'

export default {
  title() {
    return this.$t('invoices')
  },
  components: {
    InvoicesList
  }
}
</script>
