import Vue from 'vue'
import VueI18n from 'vue-i18n'
import uk from './uk'

Vue.use(VueI18n)

const messages = {
  uk
}

const i18n = new VueI18n({
  locale: 'uk',
  messages,
  fallbackLocale: 'uk'
})

export default i18n
