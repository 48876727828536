export default {
  invoices: 'Invoices',
  number: 'Number',
  date_range: 'Date range',
  net_amount: 'Net amount',
  of: 'of',
  items: 'items',
  no_invoices_title: 'You don’t have any invoices yet',
  no_invoices_text:
    'As soon as you do, they will be listed here to be printed or downloaded'
}
