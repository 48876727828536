<template>
  <div class="px-4 pt-3 sm:px-0 sm:py-1 sm:mb-3 ">
    <div
      class="flex items-center flex-wrap text-sm sm:flex-no-wrap "
      @click="openInvoice"
    >
      <div class="w-6/12 order-1 sm:w-3/12 sm:pr-2">
        <router-link
          :to="{
            name: 'Single Invoice',
            params: { id: invoice.id }
          }"
          class="text-blue-100 font-bold hover:underline"
        >
          #{{ invoice.id }}
        </router-link>
      </div>
      <div class="w-6/12 order-3 sm:w-3/12 sm:px-2">
        <p class="font-bold">{{ invoice.netAmount }}</p>
      </div>
      <div
        class="flex justify-between order-3 w-full my-1 sm:w-4/12 sm:my-0 sm:px-2 sm:order-2"
      >
        <p>{{ invoice.dateRange }}</p>
        <icon-chevron-right class="sm:hidden" />
      </div>
    </div>
    <hr class="mt-2 border-grey-30 sm:hidden" />
  </div>
</template>

<script>
import { isMobile } from '@je-pc/ui-components'
import IconChevronRight from '../icons/IconChevronRight'

export default {
  components: {
    IconChevronRight
  },
  mixins: [isMobile],
  props: {
    invoice: {
      type: Object,
      required: true
    }
  },
  methods: {
    openInvoice() {
      if (!this.isMobile) return
      this.$router.push({
        name: 'Single Invoice',
        params: { id: this.invoice.id }
      })
    }
  }
}
</script>
