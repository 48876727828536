import Home from '../views/Home.vue'
const SingleInvoice = () =>
  import(/* webpackChunkName: "singleInvoice" */ '../views/SingleInvoice.vue')

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/:id',
    name: 'Single Invoice',
    component: SingleInvoice
  }
]

export default routes
