<template>
  <div class="text-center px-6">
    <icon-no-invoices class="inline-block icon-no-invoices" />
    <h2 class="text-lg font-bold mb-3 sm:text-2xl sm:mb-1">
      {{ $t('no_invoices_title') }}
    </h2>
    <p>{{ $t('no_invoices_text') }}</p>
  </div>
</template>

<script>
import IconNoInvoices from '../icons/IconNoInvoices'

export default {
  components: {
    IconNoInvoices
  }
}
</script>

<style scoped>
.icon-no-invoices {
  width: 5.3125rem;
  height: 3.75rem;
  margin-top: 2.25rem;
  margin-bottom: 1.25rem;
}

@media screen and (min-width: 640px) {
  .icon-no-invoices {
    width: auto;
    height: auto;
    margin-top: 7.75rem;
    margin-bottom: 2.5rem;
  }
}
</style>
