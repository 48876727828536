(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@je-pc/ui-components"), require("vue-router"), require("vue"), require("@je-pc/auth"));
	else if(typeof define === 'function' && define.amd)
		define(["@je-pc/ui-components", "vue-router", "vue", "@je-pc/auth"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@je-pc/ui-components"), require("vue-router"), require("vue"), require("@je-pc/auth")) : factory(root["@je-pc/ui-components"], root["vue-router"], root["vue"], root["@je-pc/auth"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__36e7__, __WEBPACK_EXTERNAL_MODULE__6389__, __WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE__9f4f__) {
return 